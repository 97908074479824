import React from 'react';
import '../css/About.css';
import '../css/index.css';
import logo from '../assets/Kronk.svg';  // Імпортуємо зображення для відображення рейтингу.
import vommy from '../assets/voomyMain.jpg';  // Імпортуємо зображення для відображення рейтингу.
import delmar from '../assets/delmarMain.jpg';  // Імпортуємо зображення для відображення рейтингу.
import miron from '../assets/mironMain.jpg';  // Імпортуємо зображення для відображення рейтингу.



const About = () => {
    return (
        <div>
            <div className="about-h3">
                <h3>ПРО НАС</h3>
                <hr className="super-hr"/>
            </div>

            <article className="about-con bg-black">
                <div className="about-text">

                    <p className="about-logo">
                        <img src={logo} alt=""/>
                    </p>

                    <h4>Краще один раз побачити і сто разів почути, та й хоч раз прочитати :-). Саме так ми й думаємо.
                        Постало перед нами завдання написати щось про нас. І тут нас понесло. Потім задумалися, чи хтось
                        читатиме наші мемуари. Словом вирішили багато не писати, а навести кілька фактів із життя нашої
                        компанії «Кронк Строй».</h4>

                    <h4>Ми організувалися в 2013-му році. Відразу взяли на себе зобов'язання зробити один дуже великий
                        об'єкт – Voomy IT-Park у місті Харків. Спочатку ставилися до нас дуже скептично. Ще б. Усі
                        очікували, що ми привеземо зараз 30-50 людей і почнемо працювати.</h4>

                    <h4>Але точно ніхто й подумати не міг, що на об'єкт вийде 10 осіб. Так, ми не обманюємо, саме 10
                        робітників. При чому 6 з них вийшли виконувати роботи по фасадній частині та 4 особи займалися
                        склінням будівлі. Дивлячись на об'єкт, можна подумати, що 10 осіб – це нереально.</h4>

                </div>

                <div className="about-img">
                    <img src={vommy} alt=""/>
                </div>
            </article>

            <article className="about-con-alt">
                <div className="about-text-alt">
                    <h4>Але складнощі нас не лякають. Та й досвід роботи показував, що більше людей тільки створять
                        більший безлад. Загалом, взялися за роботу, дякую за повну довіру інвестору, і «вуаля», через 6
                        місяців об'єкт готовий до запуску.</h4>

                    <h4>Так саме півроку на основні роботи. Шкода, звичайно, що поки "Voomy IT-Park" поки так і не
                        введений в експлуатацію, але ми сподіваємося, що в найближчому майбутньому ситуація, що
                        склалася, покращиться і ми все-таки потрапимо на відкриття цього цікавого IT-центру. Докладніше
                        про цей проект, і не тільки про це, можна почитати та й подивитися фото на вкладці
                        «Проекти».</h4>

                    <h4>Як говориться далі більше і цікавіше. Завдяки швидкості та якості виконаної роботи, у нас почали
                        з'являтися нові, не менш цікаві та великі об'єкти. З частиною наших робіт можна ознайомитись у
                        вищезгаданому розділі «Проекти». Всі наші роботи ми просто не змогли зібрати через те, що свого
                        часу не збирали фото об'єктів, а тепер до багатьох просто фізично немає можливості дістатися,
                        але згодом ми виправимося.</h4>

                </div>

                <div className="about-img-alt">
                    <img src={delmar} alt=""/>
                </div>
            </article>

            <article className="about-con bg-black">
                <div className="about-text">
                    <h4>Нас обирають багато клієнтів за підхід до справи. Якщо комусь хочеться швидко якісно і без
                        зайвих переробок отримати готове рішення щодо оформлення фасадної частини будівлі або як у
                        випадку магазину свіжих морепродуктів “Ocean Fish” внутрішньої обробки – звертайтеся. З великою
                        насолодою вирішимо поставлені перед нами завдання. До речі, роботи з вищезгаданого магазину ми
                        зробили за 7 робочих днів, при тому, що 3 дні з них ми чекали на матеріал. Якість роботи можете
                        оцінити самі, сходивши на закупівлі якісних морепродуктів чи суші, ролів. Ось так нехитро,
                        заразом і магазин прорекламували.</h4>

                    <h4>На всі види наданих нами послуг та виконаних нами робіт компанія «Кронк Строй» дає гарантію 5
                        років. Гарантія включає і гарантійне обслуговування і обговорюється з кожним клієнтом
                        індивідуально. Також ми працюємо з багатьма заводами-виробниками матеріалів, що дає Вам
                        можливість не морочитися над їх покупкою. Тому що часто, ціни, що надаються нами, виглядають
                        цікавішими за ті, які можуть запропонувати Вам. Це пов'язано з тим, що матеріалу ми купуємо
                        багато і часто, а відповідно і ціни у нас, як у оптових покупців. А гурт завжди дешевше
                        роздрібу. Та й гарантійні зобов'язання зі своїм матеріалом дотримуватись легше. Але це в жодному
                        разі не означає, що ми не працюватимемо з матеріалом замовника. Навпаки – з великим
                        задоволенням.</h4>

                    <div className="about-img-min">
                        <img src={miron} alt=""/>
                    </div>

                    <h4>Але точно ніхто й подумати не міг, що на об'єкт вийде 10 осіб. Так, ми не обманюємо, саме 10
                        робітників. При чому 6 з них вийшли виконувати роботи по фасадній частині та 4 особи займалися
                        склінням будівлі. Дивлячись на об'єкт, можна подумати, що 10 осіб – це нереально.</h4>

                    <h4>Ось так вдалося написати «Про нас». А хотілося небагато. Але ще залишилося перерахувати основні
                        види робіт (назвемо їх «Підвиди»), за які ми беремося:</h4>

                    <ul>
                        <li>навісні вентильовані Фасади;</li>
                        <li>алюмінієві світлопрозорі конструкції;</li>
                        <li>фасади з Кортен-Сталі;</li>
                        <li>рекламні конструкції;</li>
                    </ul>

                    <h4>А з повним списком послуг можна ознайомитись у розділі <a href="aga.html"> «Послуги». </a></h4>

                    <h4>З повагою, команда «Кронк Строй».</h4>
                </div>

                <div className="about-img-max">
                    <img src={miron} alt=""/>
                </div>
            </article>
        </div>
    );
};

export default About;