import React from 'react';
import '../css/About.css';
import '../css/index.css';
import logo from '../assets/Kronk.svg';
import kort1 from '../assets/kort1.png';
import kort2 from '../assets/kort2.png';
import kort3 from '../assets/kort3.png';



const servicesData = [
    { id: 0,
        title: "РЕКЛАМНІ КОНСТРУКЦІЇ",
        content: "" },
    { id: 1,
        title: "ФАСАДИ З КОРТЕН СТАЛИ",
        content: "Кортен-сталь, сталь Кортен, Cor-Ten steel, просто Кортен. Называйте, как хотите, но сути дела это не меняет. Столкнулись с этим материалом лет 12-15 назад, точно уже и не вспомню. Так и главное, что увидел я ее первый раз не на фасаде, и не на памятнике, а на корабле. Говорю тогда товарищу, мол, что это за корабль такой — рухлядь какая-то ржавая. На что в ответ услышал, что я необразованный тип и многое интересное узнал и о себе, а главное и о Кортене… Забавная история получилась. В то время я еще не задумывался о том, что будем мы в будущем заниматься таким видом деятельности  как навесные вентилируемые фасады, но материал «зацепил» меня очень даже сильно и захотелось разобраться, что же это за зверь такой – Кортен.\n" +
            "\n" +
            "В общем, первое что узнал, да и прям не отходя от кассы (ну там, на корабле), что метал этот не совсем то и ржавый. Точнее Кортен конечно ржавеет, как и любой другой металл – коррозию никто ж не отменял. Но благодаря таким металлам как: медь, хром, никель, цинк, магний, алюминий которые путем легирования включены в его химический состав, дойдя до нужной степени ржавчины, коррозия Кортена останавливается. Заострю внимание на слове легирование. Когда впервые его услышал, даже повторить не смог. Надеюсь, солидарные со мной люди нашлись.\n" +
            "\n" ,
        content1: "Для всех нас и напишу. Легирование – это когда в состав материала добавляют примеси для улучшения его физических и химических свойств. В нашем случае, когда Кортен под воздействием влаги начинает ржаветь и структура его становится бархатистой, вышеперечисленные элементы таблицы Менделеева (я о меде, хроме, цинке и т.д.) вступают в действие. Создаётся так сказать пленка (другими словами нерастворимые соединения) которая полностью покрывает все поры на поверхности Кортена. Благодаря данному процессу (это я о легировании) материал наш поржавел немного, красивым стал, а дальше все – стоп коррозия.\n" +
            "\n" +
            "Ну как первое впечатления о Кортене. Круто – не так ли. Нет такого другого металла, чтобы и в снег и в стужу и в воду, да чтобы не рассыпался. А дальше понеслась. Начали смотреть где, как, в каких случаях, зачем, кто и когда. Набор слов получился, но так и было. Говоря о «где» и «когда» — имеется в виду немного истории. Говоря о происхождении можно сказать, что зарегистрирована марка Cor-Ten в США в  1933-ем году. Там же в штатах этот материал активно используют в строительстве, архитектуре и судостроение еще с начала 1960-хгодов. Из известных мне объектов того времени — это штаб квартира компании John Deere построена в 1964-ом году в штате Иллинойс, город , если я не ошибаюсь, Молин.  Приклею пару фотографий, чтобы Вы посмотрели, как выглядит это интересное здание." ,
        content2: "В общем, наверное, лучше таки смотреть, чем много читать. Для этого я сюда фотографий еще добавлю, а лучше посмотрите один из наших проектов  “Домик в Днепре”. На этом объекте, благодаря клиенту, мы смогли показать большую область применения Кортен стали. Если немного присмотреться, то видно, что и входная группа, и балкон, и клумбы, и барбекю и даже обрамление плитки вокруг террасной части отделано именно Кортеном.\n" +
            "\n" +
            "Ну что сказать, прониклись мы Кортеном и все тут. Наверное, больше писать не будем, ибо же: «лучше один раз увидеть, чем сто раз услышать». Единственное что хотелось бы добавить напоследок, это выделить основные свойства стали Кортен:\n" +
            "\n" +
            "Долговечность — то есть не нужно будет заморачиваться о том, чтобы реставрировать кортен спустя несколько зим.\n" +
            "Приятная бархатистая поверхность\n" +
            "Коррозия Кортена может занять до нескольких лет, а это в свою очередь дает возможность созерцать постоянно изменяющийся внешний вид фасада.\n" +
            "Большая область применения. Кортен сталь нашла область применения, от небольших элементов декора до больших строительных сооружений. Данный метал, также активно применяется в зерносушилках. Я уже не говорю о том, как Кортен сталь заняла отдельную нишу в нашем деле, ознакомиться с которой можно на главной странице нашего сайта.\n" +
            "Если Вам нужна будет помощь специалистов. Компания Кронк строй всегда готова помочь Вам. Мы можем, как помочь купить Кортен сталь, так и выполнить все возможные работы по монтажу и подготовке данного материала. Все контакты указаны на нашем сайте, в соответствующем разделе.\n" +
            "\n" +
            "Спасибо за внимание. С уважением, команда «Кронк строй»." ,
        image: kort2,
        image1: kort1,
        image2: kort3,
        },

    { id: 2,
        title: "АЛЮМІНІЄВІ СВІТЛОПРОЗОРНІ КОНСТРУКЦІЇ",
        content: ""
    },
    { id: 3,
        title: "НАВІСНІ ВЕНТИЛЮВАНІ ФАСАДИ",
        content: ""
    },
];

const ServicePage = ({ match }) => {
    const service = servicesData.find(s => s.id === parseInt(match.params.id, 10));

    return (
        <div>
            <div className="about-h3">
                <h3>{service.title}</h3>
                <hr className="super-hr"/>
            </div>

            <article className="about-con bg-black">
                <div className="about-text">

                    <p className="about-logo">
                        <img src={logo} alt=""/>
                    </p>

                    <h4>{service.content}</h4>



                </div>

                <div className="about-img">
                    <img src={service.image} alt=""/>
                </div>
            </article>

            <article className="about-con-alt">
                <div className="about-text-alt">
                    <h4>{service.content1}</h4>



                </div>

                <div className="about-img-alt">
                    <img src={service.image1} alt=""/>
                </div>
            </article>

            <article className="about-con bg-black">
                <div className="about-text">
                    <h4>{service.content2}</h4>



                </div>

                <div className="about-img-alt">
                    <img src={service.image2} alt=""/>
                </div>
            </article>


            {/*<p>{service.content}</p>*/}
            {/*<p>{service.content1}</p>*/}
            {/*<p>{service.content2}</p>*/}
        </div>
    );
};

export default ServicePage;
